export interface ShareProps {
  url: string
  text?: string
  toast: () => void
}

/** url 공유하기 함수 */
export const handleShare = (props: ShareProps) => {
  const { text, url, toast } = props

  if (navigator.share) {
    navigator
      .share({
        ...(text ? { text: `${text}${url}` } : { url }),
      })
      .catch((error) => {
        console.log(error.message)
      })
  } else {
    // 공유하기 미지원 브라우저의 경우 copy url
    navigator.clipboard
      .writeText(`${text ?? ''}${url}`)
      .then(() => {
        toast()
      })
      .catch((error) => {
        console.log(error.message)
      })
  }
}
