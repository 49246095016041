import { twMerge } from 'tailwind-merge'
import type { IconButtonProps } from '@/v1/IconButton/types'
import * as Icons from '@/v1/Icons'

const FILL_CLASSNAME_REG = /fill-[a-z]+(?:-[0-9]+)?/g

const IconButton = ({
  iconName,
  size,
  className = '',
  gaSelector,
  ...restProps
}: IconButtonProps) => {
  const Icon = Icons[iconName]

  const getPickedFillClassName = (className: string) =>
    className.match(FILL_CLASSNAME_REG)?.join(' ')

  const getExceptedFillClassName = (className: string) =>
    className.replace(FILL_CLASSNAME_REG, '').trim()

  return (
    <button
      type="button"
      className={twMerge(
        gaSelector,
        'flex items-center justify-center',
        getExceptedFillClassName(className),
      )}
      {...restProps}
    >
      <Icon size={size} className={getPickedFillClassName(className)} />
    </button>
  )
}

export default IconButton
