import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/navigation'
import clsx from 'clsx'
import { URL_PATH } from '@/consts'
import { DEFAULT_PLACEHOLDER, SearchHeaderMode } from '@/consts/search'
import useSearchModal from '@/hooks/useSearchModal'
import BackButton from '@/v1/Header/BackButton'
import useOutsideClick from '@/v1/hooks/useOutsideClick'
import { OnKeyDown } from '@/v1/Search/types'
import ResetButton from './ResetButton'
import SearchButton from './SearchButton'
import type { SearchHeaderProps } from './types'
import Wrapper from './Wrapper'

const SearchHeader = ({
  placeholder = DEFAULT_PLACEHOLDER,
  defaultKeyword = '',
  autoFocus = true,
  onBack,
  mode = SearchHeaderMode.SEARCH,
}: SearchHeaderProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchKeyword, setSearchKeyword] = useState(defaultKeyword)
  const { openSearchModal, closeSearchModal } = useSearchModal()
  const { push } = useRouter()

  useOutsideClick(inputRef, () => {
    inputRef?.current?.blur()
  })

  useEffect(() => {
    setSearchKeyword(defaultKeyword)
  }, [defaultKeyword])

  const isResultMode = mode === SearchHeaderMode.RESULT

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value)
  }

  const handleReset = () => {
    // 검색 결과 모드 :
    // defaultKeyword를 빈 값으로 검색 모달을 오픈한다.
    if (isResultMode) {
      openSearchModal({ defaultKeyword: '' })
      return
    }
    // 검색 모드 :
    // 검색어 초기화 후 input에 focus
    setSearchKeyword('')
    inputRef.current?.focus()
  }

  const handleSearch = () => {
    // 검색 결과 모드 :
    // defaultKeyword를 검색어로 검색 모달을 오픈한다.
    if (isResultMode) {
      openSearchModal({ defaultKeyword: searchKeyword })
      return
    }
    // 검색 모드 :
    // 검색어 0자 입력한 상태에서 검색 시도 시 화면에 변화 없고,
    // 커서만 깜박이며 키보드 사라지지 않음
    if (!searchKeyword) {
      inputRef?.current?.focus()
      return
    }

    push(`${URL_PATH.Search}?q=${searchKeyword}`)
    closeSearchModal()
  }

  const handleKeyDown: OnKeyDown = (event) => {
    if (event.nativeEvent.isComposing) return
    if (event.key === 'Enter') handleSearch()
  }

  return (
    <Wrapper>
      <BackButton onBack={onBack} />
      <div className="flex flex-1 font-bold">
        <input
          ref={inputRef}
          type="text"
          {...(!isResultMode && { placeholder })}
          className={clsx(
            'w-full',
            'placeholder:text-grey-400 placeholder:font-normal',
            'focus:outline-none',
            {
              'cursor-pointer': isResultMode,
            },
          )}
          value={searchKeyword}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          autoFocus={autoFocus}
          enterKeyHint="search"
          readOnly={isResultMode}
          // isResultMode일 때 input은 검색 버튼과 동일한 작업을 한다.
          {...(isResultMode && { onClick: handleSearch })}
        />
        {!!searchKeyword && <ResetButton onReset={handleReset} />}
        <SearchButton searching={!!searchKeyword} onSearch={handleSearch} />
      </div>
    </Wrapper>
  )
}

export default SearchHeader
