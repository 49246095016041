'use client'

import { PropsWithChildren } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { SearchHeaderMode } from '@/consts/search'
import useToggleBodyScroll from '@/hooks/useToggleBodyScroll'
import SearchHeader from '@/v1/Header/SearchHeader'
import type { SearchHeaderProps } from '@/v1/Header/SearchHeader/types'
import KeyWordChips from './KeywordChips'
import Modal from './Modal'

const SearchModal = NiceModal.create(
  ({ ...restProps }: PropsWithChildren<SearchHeaderProps>) => {
    const modal = useModal()
    const { onBack } = restProps

    const handleClose = () => {
      onBack?.()
      modal.hide()
    }

    useToggleBodyScroll(modal.visible)

    return (
      <Modal opened={modal.visible}>
        <SearchHeader
          onBack={handleClose}
          mode={SearchHeaderMode.SEARCH}
          {...restProps}
        />
        <KeyWordChips />
      </Modal>
    )
  },
)

export default SearchModal
