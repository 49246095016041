import IconButton from '@/v1/IconButton'

const ResetButton = ({ onReset }: { onReset?: () => void }) => {
  return (
    <IconButton
      iconName="DeleteAllFilledIcon"
      size={20}
      className="shrink-0 pl-3 pr-2 fill-grey-500"
      onClick={onReset}
    />
  )
}

export default ResetButton
