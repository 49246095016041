import { useRouter } from 'next/navigation'
import useSWR from 'swr'
import { z } from 'zod'
import { API_PATH, URL_PATH } from '@/consts/urls'
import useSearchModal from '@/hooks/useSearchModal'
import { getRandomKeywords } from '@/utils/search'
import Chip from '@/v1/Chip'

// 노출할 최대 추천 검색어 개수
const MAX_KEYWORDS = 5

const schema = z.object({
  queries: z.array(z.string()),
})

type Keywords = z.infer<typeof schema>

/**
 * 많이 찾는 검색어 Chip 목록
 */
const KeyWordChips = () => {
  const { push } = useRouter()
  const { closeSearchModal } = useSearchModal()
  const { data } = useSWR<Keywords>(API_PATH.SearchKeywords)

  const randomKeywords = getRandomKeywords(data?.queries ?? [], MAX_KEYWORDS)

  const handleClick = (keyword: string) => {
    push(`${URL_PATH.Search}?q=${keyword}`)
    closeSearchModal()
  }

  return (
    <div className="mt-6 px-md">
      <p className="prose-p3">많이 찾는 검색어</p>
      <div className="flex-wrap flex gap-2 mt-3">
        {randomKeywords.map((keyword, index) => (
          <Chip
            data-ga="search_mode_best"
            key={index}
            text={keyword}
            lineClamp
            onClick={() => handleClick(keyword)}
          />
        ))}
      </div>
    </div>
  )
}

export default KeyWordChips
