import { PropsWithChildren } from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import ModalComponent from '@/v1/Modal/index'
import Overlay from '@/v1/Modal/Overlay'
import { fullScreenStyle, modalLayoutStyle } from '@/v1/Modal/styles'

const Modal = ({
  opened = false,
  children,
}: PropsWithChildren<{ opened: boolean }>) => {
  if (!opened) return null

  return (
    <ModalComponent>
      <Overlay onClose={() => {}} />
      <div
        className={clsx(twMerge(modalLayoutStyle), fullScreenStyle)}
        role="dialog"
      >
        {children}
      </div>
    </ModalComponent>
  )
}

export default Modal
