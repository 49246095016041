'use client'

import { ReactNode } from 'react'
import { usePathname } from 'next/navigation'
import clsx from 'clsx'
import { APP_MAIN_PATHS, IOS_APP_MAIN_PATHS } from '@/consts'
import useSearchModal from '@/hooks/useSearchModal'
import useShareWithUtm from '@/hooks/useShareWithUtm'
import { getPlatformFromCookie, isWebview } from '@/utils'
import MaterialSymbol from '@/v1/MaterialSymbol'
import BackButton from './BackButton'
import { Header } from './Header'
import RightButton from './RightButton'

export interface SubPageHeaderProps {
  title?: ReactNode
  onBack?: () => void
  shareIcon?: boolean
  icon?: 'none' | 'search' | 'menu'
  onIconClick?: () => void
  gaShareIconSelector?: string
  gaIconSelector?: string
}

export const SubPageHeader = ({
  title,
  onBack,
  shareIcon = false,
  icon = 'search',
  onIconClick,
  gaShareIconSelector,
  gaIconSelector,
}: SubPageHeaderProps) => {
  const { openSearchModal } = useSearchModal()
  const { handleShare } = useShareWithUtm()
  const pathname = usePathname()
  const platform = getPlatformFromCookie()

  const gaIconButton = icon === 'search' ? 'header_search' : gaIconSelector
  const handleIconClick = () => {
    if (icon === 'search') {
      openSearchModal()
      return
    }
    onIconClick?.()
  }

  const showIconButton = icon !== 'none' && icon !== 'menu'
  const isApp = isWebview()
  // 모바일 웹뷰에서는 메인 라우트로 지정된 화면에서는 뒤로가기 버튼을 숨긴다.
  const isBackButtonHiddenInMobile =
    isApp &&
    (platform === 'ios' ? IOS_APP_MAIN_PATHS : APP_MAIN_PATHS).includes(
      pathname ?? '',
    )

  return (
    <Header>
      {/* 마이케어 웹뷰에서는 뒤로가기 버튼을 보여주지 않는다. */}
      {!isBackButtonHiddenInMobile && <BackButton onBack={onBack} />}
      {title && (
        <Header.Title
          text={title}
          align="start"
          className={clsx('pl-0', 'line-clamp-1', 'break-all', {
            'pl-6': isBackButtonHiddenInMobile,
          })}
        />
      )}
      <div className="flex h-full">
        {shareIcon && (
          <Header.IconButton
            data-ga={gaShareIconSelector}
            icon={<MaterialSymbol name="share" />}
            className="pr-2"
            onClick={handleShare}
          />
        )}
        {!isApp && showIconButton && (
          <Header.IconButton
            {...(gaIconButton ? { 'data-ga': gaIconButton } : {})}
            icon={<MaterialSymbol name={icon} />}
            className={isApp ? 'pr-4' : 'pr-2'}
            onClick={handleIconClick}
          />
        )}
        {icon !== 'none' && <RightButton />}
      </div>
    </Header>
  )
}
