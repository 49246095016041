import { useModal as useNiceModal } from '@ebay/nice-modal-react'
import SearchModal from '@/components/SearchModal'
import type { SearchHeaderProps } from '@/v1/Header/SearchHeader/types'
import useVirtuosoSnapshotReset from '../useVirtuosoSnapshotReset'

const useSearchModal = () => {
  const searchModal = useNiceModal(SearchModal)
  const { resetScroll } = useVirtuosoSnapshotReset()

  /**
   * @param placeholder
   * @param defaultKeyword
   * @param autoFocus
   * @param onBack 모달이 닫힐 때 추가 동작
   * @example
   * openSearchModal({
   *  placeholder: '검색어를 입력해 주세요.',
   *  defaultKeyword: '루닛케어',
   *  onBack: () => {
   *    alert('검색 모달 오픈')
   *  }
   * })
   */
  const openSearchModal = (props?: SearchHeaderProps) => {
    searchModal.show({ ...props })
  }

  const closeSearchModal = () => {
    searchModal.hide()
    resetScroll()
  }

  return {
    searchModal,
    openSearchModal,
    closeSearchModal,
  }
}

export default useSearchModal
