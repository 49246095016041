import { useEffect } from 'react'

const useOutsideClick = <T extends HTMLElement = HTMLElement>(
  ref: React.RefObject<T>,
  callback: (event: MouseEvent | TouchEvent) => void,
) => {
  useEffect(() => {
    const handleEvent = (event: MouseEvent | TouchEvent) => {
      const element = ref?.current

      if (!element || element.contains(event.target as Node)) {
        return
      }

      callback(event)
    }

    window.addEventListener('mouseup', handleEvent)
    window.addEventListener('touchstart', handleEvent)
    return () => {
      window.removeEventListener('mouseup', handleEvent)
      window.removeEventListener('touchstart', handleEvent)
    }
  }, [ref, callback])
}

export default useOutsideClick
