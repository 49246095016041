import { Header } from './Header'

/**
 * 헤더에 사용하는 공통 뒤로가기 버튼 컴퍼넌트.
 * onBack의 기본동작은 브라우저의 뒤로가기 버튼과 동일하다.
 */
const BackButton = ({ onBack }: { onBack?: () => void }) => {
  return <Header.BackIconButton onBack={onBack} data-ga="header_back" />
}

export default BackButton
