import clsx from 'clsx'
import MaterialSymbol from '@/v1/MaterialSymbol'

const SearchButton = ({
  searching = false,
  onSearch,
}: {
  searching: boolean
  onSearch: () => void
}) => {
  return (
    <button
      className={clsx('shrink-0', 'pr-4', {
        'pl-2': searching,
        'pl-3 cursor-default': !searching,
      })}
      onClick={onSearch}
    >
      <MaterialSymbol
        name="search"
        size={32}
        className={clsx({
          'fill-lavendar-500': searching,
          'fill-grey-200': !searching,
        })}
      />
    </button>
  )
}

export default SearchButton
