/**
 * 랜덤으로 특정 개수의 요소를 추출하는 함수
 * @param array 추출할 문자열 배열
 * @param maxElements 추출할 요소의 최대 개수
 */
export const getRandomKeywords = (array: string[], maxElements: number) => {
  const result = []
  const copiedArray = [...array]

  // 배열의 길이보다 더 많이 추출하는 경우 배열 그대로 반환한다
  if (maxElements >= copiedArray.length) return copiedArray

  for (let i = 0; i < maxElements; i++) {
    if (copiedArray.length === 0) break

    const index = Math.floor(Math.random() * copiedArray.length)
    result.push(copiedArray[index])
    copiedArray.splice(index, 1)
  }
  return result
}
