import { PropsWithChildren } from 'react'
import clsx from 'clsx'
import { V1_TABLET_WIDTH } from '@/v1/Layout/styles'

const Wrapper = ({ children }: PropsWithChildren) => {
  return (
    <div
      className={clsx(
        'supports-sticky:sticky top-0 left-0',
        'flex items-center',
        'mx-auto',
        'h-[65px]',
        'border-b border-b-lavendar-500',
        'bg-white',
        'z-10',
        V1_TABLET_WIDTH,
      )}
    >
      {children}
    </div>
  )
}

export default Wrapper
